import { formatDate } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { DIALOG_DATA, getFormattedDate } from '../../../../global.variable';
import { AppService } from '../../../app.service';
import { UsersService } from '../../../pages/pages/users/users.service';
import { LoadingSpinnerService } from '../../services/loading-spinner.service';
import { ToasterService } from '../../services/toaster.service';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';

@Component({
  selector: 'gtapp-add-edit-license-details',
  templateUrl: './add-edit-license-details.component.html',
  styleUrl: './add-edit-license-details.component.scss',
})
export class AddEditLicenseDetailsComponent implements OnInit {
  today = getFormattedDate(new Date());
  licenseForm: UntypedFormGroup = new UntypedFormGroup({
    issuer_state: new UntypedFormControl('', [Validators.required]),
    license_class: new UntypedFormControl(null, [Validators.required]),
    license_number: new UntypedFormControl(null, [Validators.required]),
    valid_till: new UntypedFormControl(this.today, [Validators.required]),
  });
  stateData: any = [];
  classData: any[] = [];
  licenseClassData: any = [];
  @Input() licenseState: any;
  @Input() companyLicense: boolean = false;

  validDate = this.today;
  selectedStateCode: any;
  licenseClassName: string = '';
  constructor(
    private appService: AppService,
    protected dialogRef: DialogRef,
    private userService: UsersService,
    private spinnerService: LoadingSpinnerService,
    private toasterService: ToasterService,
    @Inject(DIALOG_DATA) public dialogueData: any
  ) {}

  ngOnInit(): void {
    this.getStates(1);
    if (this.dialogueData) {
      this.companyLicense = this.dialogueData?.companyLicense;
      this.licenseState = this.dialogueData?.licenseState;
    }
    if (this.licenseState)
      this.licenseForm.controls['issuer_state'].setValue(this.licenseState);
  }
  getStates(countryId: any) {
    this.appService.getStates(+countryId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateData = response['data'];
        if (this.licenseState) {
          let selectedState = this.stateData.filter(
            (item: any) =>
              item.code.toLowerCase() === this.licenseState.toLowerCase()
          );
          if (selectedState.length) {
            this.selectState(selectedState[0]);
            this.licenseForm.controls['issuer_state'].setValue(
              this.licenseState
            );
          }
        }
      }
    });
  }

  selectState(event: any) {
    if (!event?.target?.selectedOptions) {
      return;
    }
    const selectedOption = event?.target?.selectedOptions[0];
    const stateId = selectedOption.value;
    const stateCode = selectedOption.getAttribute('data-code');

    this.selectedStateCode = stateCode;

    this.userService.getClassByState(stateId).subscribe((response: any) => {
      if (response['status'] === 'success') {
        this.classData = response['data'];
        this.licenseClassData = response['data'];
      } else {
        this.toasterService.setMessage({
          successMessage: '',
          errorMessage: response['message'],
        });
      }
    });
  }
  addNewClass(newLicense: string) {
    if (this.licenseForm.value['issuer_state']) {
      let data = {
        issuer_state: this.licenseForm.value['issuer_state'],
        license_class_name: newLicense,
      };
      this.userService.addNewClass(data).subscribe((response: any) => {
        this.classData = [...this.classData, response['data']];
        this.licenseForm.controls['license_class'].setValue(
          response['data'].license_class_name
        );
      });
    } else {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'Please select the state to add new licence guard.',
      });
    }
  }
  closeDialogue(data = 'close') {
    this.dialogRef.close(data);
  }

  addLicense() {
    if (this.licenseForm.valid) {
      this.licenseForm.controls['issuer_state'].setValue(
        this.selectedStateCode
      );
      this.licenseForm.value.valid_till = formatDate(
        this.licenseForm.value.valid_till,
        'yyyy-MM-dd',
        'en'
      );
      this.spinnerService.show();
      this.userService
        .addLicense(this.licenseForm.value, {
          is_subscriber: this.companyLicense ? 1 : 0,
        })
        .then((response: any) => {
          if (response['status'] == 'success') {
            this.toasterService.setMessage({
              successMessage: response['message'],
              errorMessage: '',
            });

            this.closeDialogue(response['data']);
            this.licenseForm.reset();
          } else {
            this.toasterService.setMessage({
              errorMessage: response['message'],
              successMessage: '',
            });
          }
        });
    } else {
      this.toasterService.setMessage({
        errorMessage: 'invalid data',
        successMessage: '',
      });
    }

    this.spinnerService.hide();
  }

  onLicenceClassSearch(event: any) {
    this.licenseForm.controls['license_class'].setValue(event?.target?.value);
    if (event?.target?.value?.length === 0) {
      this.licenseClassData = this.classData;
    } else {
      this.licenseClassData = this.classData.filter((item: any) =>
        item.license_class_name
          ?.toLowerCase()
          .includes(event?.target?.value?.toLowerCase())
      );
    }
  }
  onlicenseSelect(data: any) {
    if (data) {
      this.licenseForm.controls['license_class'].setValue(
        data.license_class_name
      );
    } else {
      this.licenseForm.controls['license_class'].setValue(
        this.licenseClassName
      );
    }
  }
}
