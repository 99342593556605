<div class="card dialog-box">
  <div class="card-header">
    {{ editSite ? "Edit Site Details" : "Add New Site" }}
    <span class="float-end" (click)="onCloseDialogue('close')">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>
  <div>
    <!-- Forms for Site Details -->
    <form *ngIf="!editSite" [formGroup]="siteForm">
      <!-- Stepper 1 -->

      <div>
        <div class="row row-cols-1">
          <div class="form-floating">
            <input
              type="text"
              class="form-control"
              id="siteInputField"
              formControlName="company_name"
              placeholder="Site Name"
              autocomplete="off"
            />
            <label for="company_name">Site Name</label>
          </div>

          <gtapp-address-lookup [addressForm]="siteForm">
          </gtapp-address-lookup>

          <div *ngIf="showFrom === 'addJob'">
            <div>
              <gtapp-auto-complete
                [data]="clientDataList"
                placeHolder="Client"
                searchKeyword="company_name"
                [initialValue]="clientValue"
                [itemTemplate]="ClientTemplate"
                [addInitialKey]="true"
                (inputChanged)="onClientSearch($event)"
                (selected)="onClientSelect($event)"
              >
              </gtapp-auto-complete>
            </div>

            <ng-template #ClientTemplate let-item>
              @if (item?.key === 0) {
              <div>Add New Client</div>
              } @else {
              <div>
                <div>{{ item?.company_name }}</div>
                <div class="small-font text-secondary">
                  {{ item?.address?.full_address }}
                </div>
              </div>
              }
            </ng-template>
          </div>
          <div class="row row-cols-2 mt-2">
            <div class="col">
              <button
                class="btn btn-dark btn-lg w-100"
                (click)="onCloseDialogue()"
              >
                Cancel
              </button>
            </div>

            <div class="col">
              <button
                type="submit"
                class="btn btn-primary btn-lg w-100"
                id="submitbtn1"
                (click)="updateData()"
                [disabled]="!siteForm.valid || !selectedClient"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <form *ngIf="editSite" [formGroup]="siteForm">
      <ng-container *ngIf="siteName">
        <div class="form-floating">
          <input
            class="form-control"
            formControlName="company_name"
            type="text"
            placeholder="Site Name"
            id="siteName"
          />
          <label for="siteName">Site Name</label>
        </div>
      </ng-container>
      <ng-container *ngIf="!siteName">
        <gtapp-address-lookup
          [addressForm]="siteForm"
          [editSelectedAddress]="true"
        >
        </gtapp-address-lookup>
      </ng-container>
      <div class="row row-cols-2 mt-2">
        <div class="col">
          <button
            class="btn btn-dark btn-lg btn-block w-100"
            (click)="onCloseDialogue()"
          >
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            id="submitbtn"
            (click)="updateData()"
            class="btn btn-primary btn-lg w-100"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
