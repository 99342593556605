<ng-container *ngIf="!addressValue || editSelectedAddress">
  <div class="mb-2">
    <gtapp-auto-complete
      [data]="addrLookupData"
      placeHolder="Lookup Address"
      searchKeyword="full_address"
      [itemTemplate]="addressTemplate"
      [addInitialKey]="true"
      inputId="addressLookup"
      (inputChanged)="onAddressSearch($event)"
      (selected)="addressSelected($event)"
    >
    </gtapp-auto-complete>

    <ng-template #addressTemplate let-item>
      @if (item?.key === 0) {
      <div>Enter address manually</div>
      } @else {
      <div class="small fw-semibold">
        {{ item.full_address }}
      </div>
      }
    </ng-template>
  </div>
</ng-container>

<ng-container *ngIf="editSelectedAddress">
  <form [formGroup]="addressForm">
    <div
      class="alert alert-danger"
      role="alert"
      *ngIf="
        addressForm.controls['address1'].invalid ||
        addressForm.controls['address2'].invalid ||
        addressForm.controls['postcode'].invalid ||
        addressForm.controls['city_name'].invalid ||
        addressForm.controls['state_code'].invalid
      "
    >
      Unable to get exact address, please fill in missing information
    </div>
    <div class="row g-3">
      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="address1"
          formControlName="address1"
          maxlength="30"
          placeholder="Street Number"
          [required]="true"
        />
        <label for="address1">Street Number</label>
      </div>

      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="address2"
          formControlName="address2"
          maxlength="100"
          autocomplete="off"
          placeholder="Street"
          required
        />
        <label for="address2">Street Name</label>
      </div>

      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="address3"
          formControlName="address3"
          maxlength="100"
          autocomplete="off"
          placeholder="Unit"
        />
        <label for="address3">Address Prefix (Suite/Lot/Unit)</label>
      </div>

      <div class="form-floating">
        <input
          type="text"
          class="form-control text-uppercase"
          id="city_name"
          formControlName="city_name"
          maxlength="100"
          autocomplete="off"
          placeholder="City"
          required
          (input)="forceUppercaseConditionally($event)"
        />
        <label for="city_name">Suburb</label>
      </div>

      <div class="form-floating">
        <input
          type="text"
          class="form-control"
          id="postcode"
          formControlName="postcode"
          maxlength="30"
          autocomplete="off"
          required
          placeholder="Post Code"
        />
        <label for="postcode">Post Code</label>
      </div>

      <div class="form-floating">
        <select
          class="form-select"
          id="state_code"
          formControlName="state_code"
          placeholder="State"
          required
        >
          <option *ngFor="let state of stateDataList" [value]="state.code">
            {{ state.code }}
          </option>
        </select>
        <label for="state_code">State</label>
      </div>
    </div>
  </form>
</ng-container>

<div *ngIf="addressValue && !editSelectedAddress">
  <div class="d-flex p-1 m-auto align-items-center">
    <div class="flex-grow-1">
      {{ addressValue }}
    </div>
    <div>
      <span (click)="onSearchAnotherAddress()" class="me-1">
        <i class="fa-solid fa-rotate-right fa-2x"></i>
      </span>
    </div>
  </div>
</div>
