import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { Observable, lastValueFrom, map } from 'rxjs';
import { gTDB } from './shared/services/db';
import { DeviceInfoService } from './shared/services/device-info.service';
@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private http: HttpClient,
    httpBackend: HttpBackend,
    private route: Router,
    private router: Router,
    private swUpdate: SwUpdate,
    private deviceInfoService: DeviceInfoService
  ) {
    this.http = new HttpClient(httpBackend);
  }

  getUserData() {
    return JSON.parse(localStorage.getItem('userData') || '{}');
  }

  setUserData(data: any) {
    this.sendToSW(data);
    localStorage.setItem('userData', JSON.stringify(data));
  }
  sendToSW(data: any) {
    let payload: any = { type: 'userData' };
    Object.keys(data).forEach((key) => {
      if (['access', 'profile', 'subscriber'].includes(key)) {
        payload[key] = data[key];
      }
    });
    navigator?.serviceWorker?.controller?.postMessage(payload);
  }
  getToken() {
    if (this.getUserData()) {
      return this.getUserData().access;
    }
  }
  refreshToken() {
    return this.http.post('api/token/refresh/', {
      refresh: this.getUserData().refresh,
    });
  }

  getIP(): Observable<any> {
    return this.http.get('https://jsonip.com');
  }
  getGPS() {
    return this.http.get('https://freeipapi.com/api/json');
  }
  cityLookup(input: any) {
    const body = { search_str: input };
    return this.http.post('api/shared/suburb_lookup/', body);
  }
  getCountries() {
    return this.http.get('api/shared/country/');
  }
  getStates(countryId: any) {
    return this.http.get('api/shared/state/' + countryId + '/');
  }
  addressLookupShared(input: any, params?: any) {
    return this.http.post(
      'api/shared/address/',
      { search_str: input },
      { params: params }
    );
  }
  getSmartNetLinkStatus() {
    return this.http.get('api/client/smartnet/link_status/');
  }
  logOut() {
    let user = this.getUserData();
    let email = user?.profile?.email;
    let expiryToken = user?.token_expiry;
    localStorage.setItem('email', email);
    let backupEmail: any = localStorage.getItem('email');
    localStorage.clear();
    sessionStorage.clear();
    setTimeout(() => {
      if (expiryToken) {
        localStorage.setItem('email', backupEmail);
      }
      this.route.navigate(['/login']);
      window.location.reload();
    }, 1);
  }

  checkAdduserLinkValidation(key: any) {
    return this.http
      .get(`api/register_login/check_link_validity/`, {
        params: { b64_string: key },
      })
      .pipe(map((response: any) => response));
  }
  async formDataApi(path: String, formData: any, admin = false, params?: any) {
    let auth_token = this.getToken();

    const apiResponse: any =
      await this.deviceInfoService.getApiHeadersAndExtras(auth_token);
    const headerDict = apiResponse?.headerDict;
    const reqBody = apiResponse?.reqBody;

    formData.append(
      'device_info',
      JSON.stringify(await this.deviceInfoService.getDeviceDetail())
    );
    Object.keys(reqBody).forEach((key) => {
      formData.append(key, reqBody[key]);
    });

    return await lastValueFrom(
      this.http.post(`${admin ? 'admin' : 'api'}/${path}/`, formData, {
        headers: headerDict,
        params: params,
      })
    );
  }

  addFeedBack(requestData: any) {
    let body = {
      ...requestData,
    };
    return this.http
      .post(`api/external_api/save_feedback/`, body)
      .pipe(map((response: any) => response));
  }
  postErrorToBackend(requestData: any) {
    return this.http
      .post(`api/external_api/ui_error/`, requestData)
      .pipe(map((response: any) => response));
  }

  async gtExternalLink(url: any, body: any, token: any, addGps = true) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('token', token);
    headers = headers.append('Content-type', 'application/json');
    headers = headers.append('Accept', 'application/json');
    let requestBody: any = body;
    if (addGps) {
      requestBody.device_info = await this.deviceInfoService.getDeviceDetail();
    }
    requestBody.isPwa = Boolean(
      window.matchMedia('(display-mode: standalone)').matches
    );

    return await lastValueFrom(
      this.http.post(`api/${url}/`, requestBody, { headers })
    );
  }

  getReverseLocation(lat: any, lon: any) {
    // use this with caution ; this api may be deprecated or most probably restricted.
    let params = {
      location: `${lon}, ${lat}`,
      f: 'pjson',
      distance: 50000,
      outSR: '',
    };
    return this.http
      .get(
        'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode',
        { params: params }
      )
      .pipe(map((response: any) => response));
  }
  async updateGuardTrackerApp(falseUpdate = false) {
    if (this.swUpdate?.isEnabled) {
      try {
        if (await this.swUpdate.checkForUpdate()) {
          const updateApp = await this.swUpdate?.activateUpdate();
          sessionStorage.setItem('updateAvailable', 'true');
        } else if (falseUpdate) {
          sessionStorage.setItem('updateAvailable', 'true');
        }
      } catch (error) {
        // Handle errors appropriately, e.g., log or display an error message.
        console.error('Update error:', error);
      }
    }
  }

  async getVersionInfo() {
    // api called in login to check whether the Ui has been updated or not
    return lastValueFrom(this.http.get('api/shared/get_version_info/'));
  }

  async updateAppVersionIndexedDb(version: string) {
    await gTDB.userData.delete('appVersion');
    await gTDB.userData.add({ version: version }, 'appVersion');
  }
  async appVersionCheck(latestVersion?: any) {
    return new Promise(async (resolve) => {
      try {
        if (!this.swUpdate.isEnabled) {
          resolve(null);
        }

        if (!latestVersion) {
          const response: any = await this.getVersionInfo().catch(() =>
            resolve(null)
          );
          if (response?.status === 'success') {
            latestVersion = response;
          }
        }
        const currentVersion: any = await this.getCurrentVersionInfo();

        if (
          currentVersion?.version &&
          latestVersion?.last_item?.version !== currentVersion?.version
        ) {
          this.updateAppVersionIndexedDb(latestVersion?.last_item?.version);
          this.updateGuardTrackerApp(true);
        }
        if (!currentVersion?.version) {
          this.updateAppVersionIndexedDb(latestVersion?.last_item?.version);
        }

        resolve(currentVersion);
      } catch (error) {
        resolve(null);
      }
    });
  }
  async getCurrentVersionInfo() {
    return new Promise(async (resolve) => {
      try {
        setTimeout(async () => {
          const data = await gTDB.userData.get('appVersion');

          resolve(data);
        }, 500);
      } catch (error) {
        console.log(error, 'INDEXED DB VERSION ERROR');
        resolve([]);
      }
    });
  }
  updateOnBoardingValue(key: string) {
    const onBoardInProgress = JSON.parse(
      sessionStorage.getItem('onBoardingProgress') || '{}'
    );
    if (Object.keys(onBoardInProgress)?.length) {
      onBoardInProgress[key] = true;
      sessionStorage.setItem(
        'onBoardingProgress',
        JSON.stringify(onBoardInProgress)
      );
    }
  }
}
