import { Component, Input, OnInit } from '@angular/core';
import { AppService } from '../../../app.service';
import { ModelDialogueService } from '../modal-dialogue/model-dialogue.service';
import { SaveNewAddressComponent } from '../save-new-address/save-new-address.component';

@Component({
  selector: 'gtapp-address-lookup',
  templateUrl: './address-lookup.component.html',
  styleUrl: './address-lookup.component.scss',
})
export class AddressLookupComponent implements OnInit {
  @Input() addressForm: any;
  @Input() editSelectedAddress: boolean = false;

  invalidFields: any = [];
  addrLookupData: any = [];
  stateDataList: any = [];
  addressValue: string = '';

  constructor(
    private dialogService: ModelDialogueService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.getStates(1);
    if (!this.editSelectedAddress) {
      this.updateFullAddress(this.addressForm.value);
    }
  }
  getStates(countryId: any) {
    this.appService.getStates(+countryId).subscribe((response: any) => {
      if (response['status'] == 'success') {
        this.stateDataList = response['data'];
      }
    });
  }
  forceUppercaseConditionally(event: any) {
    this.addressForm.controls['city_name'].setValue(
      event.target.value.toUpperCase()
    );
  }

  onSearchAnotherAddress() {
    const addressFields = [
      'address1',
      'address2',
      'address3',
      'city_name',
      'postcode',
      'state_code',
      'latitude',
      'longitude',
    ];
    Object.keys(this.addressForm.controls).forEach((key) => {
      if (addressFields.includes(key)) {
        this.addressForm.get(key).reset();
      }
    });

    this.addressValue = '';
    this.editSelectedAddress = false;

    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById('addressLookup');
      if (element) {
        element?.focus();
      }
    }, 100);
  }
  addPlace() {
    const dialogRef = this.dialogService.open(SaveNewAddressComponent, {
      data: { showFrom: 'fullAddressRequired' },
    });
    dialogRef.afterClosed().subscribe((value: any) => {
      if (value && value != 'close') {
        this.addressSelected(value);
      }
    });
  }
  updateFullAddress(address: any) {
    if (
      address?.address1 &&
      address?.address2 &&
      address?.city_name &&
      address?.postcode &&
      address?.state_code
    ) {
      const address3 = address?.address3 ? `${address?.address3}/` : '';
      const address1 = address?.address1 || '';
      const address2 = address?.address2 || '';
      const city_name = address?.city_name || '';
      const state_code = address?.state_code || '';
      const postcode = address?.postcode || '';
      this.addressValue =
        address?.full_address ||
        `${address3}${address1} ${address2} ${city_name} ${state_code} ${postcode}`.trim();
    }
  }
  addressSelected(address: any) {
    if (address?.key === 0) {
      this.addPlace();
    } else if (address && typeof address === 'object') {
      this.editSelectedAddress = false;

      this.addressForm.patchValue({
        address1: address?.address1,
        address2: address?.address2,
        address3: address?.address3,
        city_name: address?.city_name,
        postcode: address?.postcode,
        state_code: address?.state_code,
        country_name: address?.country_name,
        latitude: address?.latitude,
        longitude: address?.longitude,
      });
      this.updateFullAddress(this.addressForm.value);
    }
  }

  onAddressSearch(addressSearch: any) {
    if (addressSearch?.target?.value?.length > 2) {
      this.appService
        .addressLookupShared(addressSearch.target.value)
        .subscribe((res: any) => {
          this.addrLookupData = res['data'];
        });
    }
  }
}
