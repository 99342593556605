import { formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Optional,
  Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import {
  DIALOG_DATA,
  convertTime12to24,
  getFormattedDate,
  getFormattedDateTime,
  getFormattedTime,
} from '../../../../global.variable';
import { DialogRef } from '../modal-dialogue/model-dialogue.ref';
import { ToasterService } from '../../services/toaster.service';
@Component({
  selector: 'gtapp-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrl: './scheduler.component.scss',
})
export class SchedulerComponent implements OnInit {
  repeatInterval: any;
  selectedMonthType: any;

  selectedRepeatType: any;
  selectedWeekDays: any = [];
  errorMessage: any;
  validStartDate = getFormattedDate();
  validateEndDate = getFormattedDate();

  startDateTime: any;
  endDateTime: any;
  endDateTimeMin: any;

  constructor(
    @Optional() protected dialogRef: DialogRef,
    private ngZone: NgZone,
    @Optional() @Inject(DIALOG_DATA) public dialogueData: any,
    private toasterService: ToasterService
  ) {}

  @Input() schedulerForm: any;
  @Input() dialogBox: boolean = true;
  @Input() editSchedule: any;
  @Input() repeatAllowed: boolean = true;

  @Output() emitData = new EventEmitter();
  showRecurringView: boolean = false;

  repeatTypes = [
    { value: 'daily', label: 'Daily' },
    { value: 'weekly', label: 'Weekly' },
    { value: 'monthly', label: 'Monthly' },
  ];
  frequencyDetails = [
    {
      value: 1,
      name: 'first',
    },
    {
      value: 2,
      name: 'second',
    },
    {
      value: 3,
      name: 'third',
    },
    {
      value: 4,
      name: 'fourth',
    },
    {
      value: -1,
      name: 'last',
    },
  ];
  selectedDays: any = [];
  availabledays = Array.from({ length: 28 }, (_, i) => i + 1);

  ngOnInit(): void {
    if (this.dialogueData) {
      this.schedulerForm = this.dialogueData?.schedulerForm;
      this.dialogBox = this.dialogueData?.dialogBox
        ? this.dialogueData?.dialogBox
        : true;
      this.editSchedule = this.dialogueData?.editSchedule;
      this.repeatAllowed = this.dialogueData?.repeatAllowed
        ? this.dialogueData?.repeatAllowed
        : true;
    }

    if (this.editSchedule == true) {
      this.loadExistingData();
    } else {
      this.loadSessionData();
    }
    // this.shiftClick('startDay');
  }
  getMinTimeValue() {
    if (
      this.schedulerForm.value.start_day === this.schedulerForm.value.end_day
    ) {
      return this.schedulerForm.value.start_time;
    }
    return null;
  }
  validateEndDay() {
    this.validateEndDate = getFormattedDate(this.schedulerForm.value.start_day);
    this.schedulerForm.controls['end_day'].setValue(this.validateEndDate);
  }
  validateEndTime() {
    this.changeEndDate();
  }
  changeStartDateTime(event: any) {
    this.schedulerForm.controls['start_day'].setValue(
      getFormattedDate(event.target.value)
    );
    this.schedulerForm.controls['start_time'].setValue(
      getFormattedTime(event.target.value)
    );
    this.changeEndDate();
  }
  changeEndDateTime(event: any) {
    const startDateTime = new Date(this.startDateTime);
    const endDateTime = new Date(this.endDateTime);

    // If end date/time is earlier than start date/time, clear it or handle as needed
    if (endDateTime < startDateTime) {
      this.toasterService.setMessage({
        successMessage: '',
        errorMessage: 'End time must be greater than start time.',
      });
      this.schedulerForm.controls['end_day'].setValue(null);
    } else {
      this.schedulerForm.controls['end_day'].setValue(
        getFormattedDate(event.target.value)
      );
      this.schedulerForm.controls['end_time'].setValue(
        getFormattedTime(event.target.value)
      );
    }
  }
  changeEndDate() {
    const scheduleStartDateTime = new Date(
      this.schedulerForm.value.start_day +
        ' ' +
        this.schedulerForm.value.start_time
    );
    const scheduleEndDateTime = new Date(
      this.schedulerForm.value.end_day + ' ' + this.schedulerForm.value.end_time
    );

    if (scheduleStartDateTime.getTime() > scheduleEndDateTime.getTime()) {
      let tomorrow = scheduleStartDateTime;
      tomorrow.setDate(tomorrow.getDate() + 1);

      this.validateEndDate = getFormattedDate(tomorrow);

      this.schedulerForm.controls['end_day'].setValue(this.validateEndDate);
    } else {
      this.validateEndDate = getFormattedDate(
        this.schedulerForm.value.start_day
      );
    }
    this.formatStartEndDateTime();
  }
  loadExistingData() {
    this.selectedRepeatType = this.schedulerForm.value.repeat_type;
    this.showRecurringView = this.selectedRepeatType === 'once' ? false : true;

    if (!this.schedulerForm.value.start_time) {
      this.schedulerForm.controls['start_time'].setValue(getFormattedTime());
    }
    if (!this.schedulerForm.value.end_time) {
      let endDateTime = new Date().setHours(new Date().getHours() + 1);
      this.schedulerForm.controls['end_time'].setValue(
        getFormattedTime(endDateTime)
      );
    }

    this.repeatInterval = this.schedulerForm.value.repeat_interval;
    if (this.selectedRepeatType === 'weekly')
      this.selectedWeekDays = this.schedulerForm.value.repeat_details;
    if (this.schedulerForm.value.repeat_details) this.selectedMonthType = 'day';

    const scheduleStartTime = new Date(
      this.schedulerForm.value.start_day +
        ' ' +
        this.schedulerForm.value.start_time
    );

    if (scheduleStartTime.getTime() < new Date().getTime()) {
      this.validStartDate = getFormattedDate();
      let tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);
      this.schedulerForm.controls['start_day'].setValue(
        getFormattedDate(tomorrow)
      );
    }
    this.changeEndDate();
  }

  formatDateWithoutTime = (date: any) => {
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };
  formatStartEndDateTime() {
    this.startDateTime = getFormattedDateTime(
      this.schedulerForm.value.start_day +
        ' ' +
        this.schedulerForm.value.start_time
    );

    this.endDateTime = getFormattedDateTime(
      this.schedulerForm.value.end_day + ' ' + this.schedulerForm.value.end_time
    );
  }

  loadNewData() {
    this.selectedRepeatType = 'once';
    // format start and end day on initial data
    this.schedulerForm.controls['start_day'].setValue(getFormattedDate());
    this.schedulerForm.controls['end_day'].setValue(getFormattedDate());
    this.schedulerForm.controls['start_time'].setValue(getFormattedTime());
    let endDateTime = new Date().setHours(new Date().getHours() + 1);
    this.schedulerForm.controls['end_time'].setValue(
      getFormattedTime(endDateTime)
    );

    this.saveSessionData();
    this.formatStartEndDateTime();
  }

  onCloseDialogue() {
    if (this.dialogBox) {
      this.dialogRef.close(false);
    } else {
      this.emitData.emit(false);
    }
  }
  changeMonthType(type: any) {
    this.schedulerForm.controls['month_repeat_case'].setValue(type);
    this.selectedMonthType = type;

    if (type == 'day') {
      this.schedulerForm.controls['position'].setValue(null);
      this.schedulerForm.controls['week_day'].setValue(null);
      this.schedulerForm.controls['repeat_details'].setValue([]);
    } else {
      this.selectedDays = null;
      this.schedulerForm.controls['position'].enable();
      this.schedulerForm.controls['week_day'].enable();
      this.schedulerForm.controls['repeat_details'].setValue([]);
    }
  }
  makeRecuringView() {
    this.showRecurringView = true;
    this.selectedRepeatType = 'daily';
    let endDate = new Date(this.schedulerForm.value.start_day);
    endDate.setDate(endDate.getDate() + 14);
    this.schedulerForm.controls['end_day'].setValue(getFormattedDate(endDate));
    this.schedulerForm.controls['repeat_type'].setValue(
      this.selectedRepeatType
    );
    this.formatStartEndDateTime();
    this.saveSessionData();
  }
  cancelRecuringView() {
    this.ngZone.run(() => {
      setTimeout(() => {
        this.schedulerForm.controls['repeat_details'].removeValidators(
          Validators.required
        );

        this.schedulerForm.controls['repeat_details'].updateValueAndValidity();
      });
    });
    this.showRecurringView = false;
    this.selectedRepeatType = 'once';
    this.schedulerForm.controls['repeat_type'].setValue(
      this.selectedRepeatType
    );
    this.schedulerForm.controls['end_day'].setValue(getFormattedDate());
    this.saveSessionData();
    this.formatStartEndDateTime();
  }

  clearTime(event: any) {
    this.schedulerForm.value.end_time = '';
  }
  updateRepeatDetails() {
    if (
      this.schedulerForm.value?.position &&
      this.schedulerForm.value?.week_day
    ) {
      let repeatDetails = this.schedulerForm.value?.repeat_details || [];
      repeatDetails.push({
        position: this.schedulerForm.value?.position,
        week_day: this.schedulerForm.value?.week_day,
      });
      this.schedulerForm.controls['repeat_details'].setValue(repeatDetails);
    }
  }

  onAlertDismis() {
    this.errorMessage = undefined;
  }
  submitSchedule() {
    this.schedulerForm.value.start_time = convertTime12to24(
      this.schedulerForm.value.start_time
    );
    this.schedulerForm.value.end_time = convertTime12to24(
      this.schedulerForm.value.end_time
    );
    this.schedulerForm.value.start_day =
      this.schedulerForm.value.start_day == ''
        ? ''
        : formatDate(this.schedulerForm.value.start_day, 'yyyy-MM-dd', 'en');
    this.schedulerForm.value.end_day =
      this.schedulerForm.value.end_day == null
        ? null
        : formatDate(this.schedulerForm.value.end_day, 'yyyy-MM-dd', 'en');
    let timezoneOffset = new Date();

    let data = {
      time_offset: timezoneOffset.getTimezoneOffset(),
      ...this.schedulerForm.value,
    };
    if (this.dialogBox) {
      this.dialogRef.close(data);
    } else {
      this.emitData.emit(data);
    }
    this.saveSessionData();
  }
  shiftClick(elementID: string) {
    setTimeout(() => {
      var element = <HTMLInputElement>document.getElementById(elementID);
      element?.click();
      element?.focus();
    }, 100);
  }

  onRepeatTypeChange(event: any) {
    this.selectedRepeatType = event;
    this.saveSessionData();
    this.schedulerForm.controls['repeat_details'].setValue([]);
    this.ngZone.run(() => {
      setTimeout(() => {
        this.schedulerForm.controls['repeat_details'].removeValidators(
          Validators.required
        );
        if (['monthly', 'weekly'].includes(event)) {
          this.schedulerForm.controls['repeat_details'].setValidators(
            Validators.required
          );
        }
        this.schedulerForm.controls['repeat_details'].updateValueAndValidity();
      });
    });
  }

  loadSessionData() {
    let schedulerData = JSON.parse(
      sessionStorage.getItem('schedulerData') || '{}'
    );

    if (Object.keys(schedulerData?.schdeuleData || {})?.length) {
      if (schedulerData?.schdeuleData?.formData) {
        this.schedulerForm.patchValue(schedulerData?.schdeuleData?.formData);
      }
      if (schedulerData?.schdeuleData?.selectedRepeatType) {
        this.selectedRepeatType =
          schedulerData?.schdeuleData?.selectedRepeatType;
        this.repeatInterval = schedulerData?.schdeuleData?.repeatInterval;
        this.selectedMonthType = schedulerData?.schdeuleData?.selectedMonthType;
        this.showRecurringView =
          this.selectedRepeatType == 'once' ? false : true;
      }
      this.formatStartEndDateTime();
    } else {
      this.loadNewData();
    }
  }

  saveSessionData() {
    if (!this.editSchedule)
      sessionStorage.setItem(
        'schedulerData',
        JSON.stringify({
          schdeuleData: {
            formData: this.schedulerForm.value,
            selectedRepeatType: this.selectedRepeatType,
            repeatInterval: this.repeatInterval,
            selectedMonthType: this.selectedMonthType,
          },
        })
      );
  }
}
