<div class="card" style="padding: 0 1rem">
  <div class="card-header">
    Installation Guide
    <button
      type="button"
      class="btn-close float-end"
      aria-label="Close"
      (click)="close('close')"
    ></button>
  </div>
  <div class="card-body" id="install-app" style="overflow-y: auto">
    <div class="installation-guide-dialog">
      <div *ngIf="currentStep >= 0" class="installation-step">
        <img
          (click)="onOpenImage($event, imgRef)"
          [src]="installationSteps[currentStep]?.imgSrc"
          alt="Step Image"
          style="width: 100%"
        />
      </div>

      <div class="row">
        <div class="col" *ngIf="currentStep > 0">
          <button type="button" class="btn btn-primary" (click)="navigate(-1)">
            Back
          </button>
        </div>
        <div class="col" *ngIf="currentStep < installationSteps?.length - 1">
          <button type="button" class="btn btn-primary" (click)="navigate(1)">
            Next
          </button>
        </div>
        <div class="col" *ngIf="currentStep === installationSteps?.length - 1">
          <button
            type="button"
            class="btn btn-primary"
            (click)="close('close')"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<ng-template #imgRef let-data let-imgTempRef="dialogRef">
  <div class="card" style="margin: 0">
    <div class="card-body" (click)="imgTempRef.close()">
      <img
        src="{{ fullImgSrc }}"
        style="
          max-width: 100%;
          cursor: pointer;
          max-height: 95vh;
          border: 3px solid var(--bs-secondary);
          border-radius: 2%;
        "
        class="modal-hover-opacity"
      />
    </div>
  </div>
</ng-template>
