<div [ngClass]="dialogBox ? 'dialog-box card' : ''">
  <div *ngIf="dialogBox" class="card-header">
    Schedule
    <span class="float-end" (click)="onCloseDialogue()">
      <i class="fa-solid fa-xmark"></i>
    </span>
  </div>
  <div>
    <div
      *ngIf="errorMessage"
      class="alert alert-warning alert-dismissible fade show"
      role="alert"
    >
      {{ errorMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="onAlertDismis()"
        aria-label="Close"
      ></button>
    </div>
    <form [formGroup]="schedulerForm" appFormEnterAction>
      <div class="row row-cols-1 g-2" *ngIf="!showRecurringView">
        <div class="form-floating">
          <input
            id="startDay"
            class="form-control"
            type="datetime-local"
            placeholder="Pick Date & Time"
            [(ngModel)]="startDateTime"
            [ngModelOptions]="{ standalone: true }"
            (change)="changeStartDateTime($event)"
          /><label for="startDay"> Start Date & Time</label>
        </div>

        <div class="form-floating">
          <input
            id="endDay"
            type="datetime-local"
            class="form-control"
            placeholder="Pick Date & Time"
            [(ngModel)]="endDateTime"
            [disabled]="!startDateTime"
            [min]="startDateTime"
            [ngModelOptions]="{ standalone: true }"
            (change)="changeEndDateTime($event)"
          /><label for="endDay"> End Date & Time</label>
        </div>
      </div>

      <div *ngIf="showRecurringView">
        <div class="row row-cols-2 g-2">
          <div class="form-floating col-12">
            <input
              type="date"
              id="startDay"
              class="form-control"
              placeholder="Start Date"
              formControlName="start_day"
              [min]="validStartDate"
              (change)="validateEndDay()"
            />
            <label for="startDay" class="form-label">Start Date</label>
          </div>
          <div class="form-floating col-6">
            <input
              type="time"
              class="form-control"
              id="startTime"
              placeholder="Start Time"
              (change)="validateEndTime()"
              formControlName="start_time"
            />
            <label for="startTime" class="form-label">Start Time</label>
          </div>
          <div class="col-6">
            <div class="form-floating">
              <input
                class="form-control"
                placeholder="End Time"
                id="endTime"
                formControlName="end_time"
                (change)="validateEndTime()"
                type="time"
              /><label for="inputAssignee">End Time</label>
            </div>
          </div>

          <!-- Recurring Event Details -->

          <!-- Iterate over each option -->
          <div class="text-center col-12 py-2">
            <div
              class="form-check form-check-inline"
              *ngFor="let option of repeatTypes"
            >
              <!-- Bootstrap class for inline radio buttons -->
              <input
                class="form-check-input"
                type="radio"
                name="repeat_type"
                id="{{ option.value }}"
                [value]="option.value"
                formControlName="repeat_type"
                [(ngModel)]="selectedRepeatType"
                (change)="onRepeatTypeChange(option.value)"
              />
              <label class="form-check-label" for="{{ option.value }}">
                {{ option.label }}
              </label>
            </div>
          </div>

          <div
            class="col-12 text-center py-2"
            *ngIf="
              selectedRepeatType === 'daily' ||
              selectedRepeatType === 'weekly' ||
              selectedRepeatType === 'monthly'
            "
          >
            <div class="d-flex align-items-center justify-content-evenly g-2">
              <div>Recur every</div>

              <div class="w-25">
                <input
                  class="form-control form-control-sm"
                  type="tel"
                  formControlName="repeat_interval"
                  value="1"
                />
              </div>
              <div>
                {{
                  selectedRepeatType === "daily"
                    ? "day(s)"
                    : selectedRepeatType === "weekly"
                    ? "week(s) on"
                    : "month(s) on"
                }}
              </div>
            </div>
          </div>

          <ng-container *ngIf="selectedRepeatType === 'weekly'">
            <div class="form-floating col-12">
              <select
                class="form-control"
                id="weekDays"
                multiple
                [(ngModel)]="selectedWeekDays"
                formControlName="repeat_details"
              >
                <option
                  *ngFor="
                    let day of [
                      'Monday',
                      'Tuesday',
                      'Wednesday',
                      'Thursday',
                      'Friday',
                      'Saturday',
                      'Sunday'
                    ]
                  "
                  [value]="day"
                >
                  {{ day }}
                </option>
              </select>
              <label for="weekDays">Select Week Days</label>
            </div>
          </ng-container>

          <ng-container *ngIf="selectedRepeatType === 'monthly'">
            <div
              class="form-floating col-12"
              (click)="changeMonthType('day')"
              [ngClass]="selectedMonthType === 'day' ? '' : 'month-type'"
            >
              <select
                class="form-control"
                id="daysOfMonth"
                multiple
                [(ngModel)]="selectedDays"
                formControlName="repeat_details"
              >
                <option *ngFor="let aDay of availabledays" [value]="aDay">
                  {{ aDay }}
                </option>
              </select>
              <label for="daysOfMonth">Days of Month</label>
            </div>
            <div class="text-center font-weight-bold col-12">OR</div>
            <div
              class="col-12 row row-cols-1 g-2"
              (click)="changeMonthType('date')"
              [ngClass]="selectedMonthType === 'date' ? '' : 'month-type'"
            >
              <div class="form-floating col-12">
                <select
                  class="form-control"
                  id="positionSelect"
                  formControlName="position"
                  (change)="updateRepeatDetails()"
                >
                  <option
                    *ngFor="let freq of frequencyDetails"
                    [value]="freq.value"
                  >
                    {{ freq.name | titlecase }}
                  </option>
                </select>
                <label for="positionSelect">Select Position</label>
              </div>
              <div class="form-floating col-12">
                <select
                  class="form-control"
                  id="weekDaySelect"
                  formControlName="week_day"
                  (change)="updateRepeatDetails()"
                >
                  <option
                    *ngFor="
                      let day of [
                        'Monday',
                        'Tuesday',
                        'Wednesday',
                        'Thursday',
                        'Friday',
                        'Saturday',
                        'Sunday'
                      ]
                    "
                    [value]="day"
                  >
                    {{ day }}
                  </option>
                </select>

                <label for="weekDaySelect">Select Week Day</label>
              </div>
            </div>
          </ng-container>

          <div class="form-floating col-12">
            <input
              type="date"
              class="form-control"
              id="endDay"
              placeholder="End Date"
              [min]="validateEndDate"
              formControlName="end_day"
            />
            <label for="endDay">End Date</label>
          </div>
        </div>
      </div>
      <div class="mt-3 w-100">
        <button
          class="btn btn-secondary btn-md w-100 btn-lg"
          (click)="
            showRecurringView ? cancelRecuringView() : makeRecuringView()
          "
        >
          {{ showRecurringView ? "Once" : "Repeat" }}
        </button>
      </div>

      <div class="row row-cols-2 mt-4" *ngIf="dialogBox">
        <div class="col">
          <button class="btn btn-lg btn-dark w-100" (click)="onCloseDialogue()">
            Cancel
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-lg btn-primary w-100"
            (click)="submitSchedule()"
            [disabled]="!schedulerForm.valid"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
